import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function NoData() {
    return (
        <Alert variant="warning">
            <p>
                Apologies, we were not able to calculate an estimate for your home.
                Please contact us to set up an in-person estimate with our team.
                <a href="tel:+16234695513">(623) 469-5513</a>
            </p>
            <p>
                Below is a list of services we provide and the packages we offer.
            </p>
        </Alert>
    );
}
