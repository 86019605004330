import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function DontService() {
  return (
    <Alert variant="danger">
      Apologies, we currently do not service your area.
    </Alert>
  );
}
