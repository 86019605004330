//function that calls the online estimates API
import axios from 'axios';

export const createOnlineEstimate = async (address: string, city: string, state: string, zipcode: string) => {
    const body = {
        address: address,
        city: city,
        state: state,
        zipcode: zipcode
    }
    try {
        const response = await axios.post('https://ttp-calc-api.azurewebsites.net/api/createOnlineEstimate?', body);
        return response;
    }
    catch (error) {
        return { status: 400, data: "Error" }
    }
}

