import { useState, useCallback } from 'react';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { createOnlineEstimate } from './utils/onlineEstimatesAPI';
import { checkAddress } from './utils/googleAPI';
import Estimates from './Estimates';
import NoData from './NoData';
import DontService from './DontService';

import Logo from './ttpLogo.png';

// Define the Type for Address
interface Address {
  label: string;
  value: {
    place_id: string;
  };
}

interface Estimate {
  pricing: {
    deluxePrice: number;
    ultimatePrice: number;
    maximumPrice: number;
  };
}

const serviceZipCodes = ["85086", "85085", "85083", "85310", "85266", "85331"];

// Helper function to extract address components
function extractAddressComponents(formatted_address: string) {
  const [addr, city, rest] = formatted_address.split(',');
  const [state, zip] = rest.trim().split(' ');
  return { addr, city, state, zip };
}

function App() {
  const [address, setAddress] = useState<Address | null>(null);
  const [calculationError, setCalculationError] = useState<string | null>(null);
  const [serviceAreaError, setServiceAreaError] = useState<string | null>(null);
  const [calculating, setCalculating] = useState<boolean>(false);
  const [estimate, setEstimate] = useState<Estimate | null>(null);

  const handleAddressChange = useCallback((address: Address | null) => {
    setAddress(address);
  }, []);

  const handleCalculateEstimate = useCallback(async () => {
    setEstimate(null);
    setCalculationError(null);
    setServiceAreaError(null);
    setCalculating(true);

    if (!address) {
      setCalculating(false);
      return;
    }

    const selectedAddress = address;
    // Check if address is in Arizona
    if (!selectedAddress.label.includes("AZ")) {
      setServiceAreaError("Sorry, we don't currently service your area");
      setCalculationError(null);
      setCalculating(false);
      return;
    }

    const place_id = selectedAddress.value.place_id; // Get place_id

    // Use Geocoding API to verify the address
    const googleResponse = await checkAddress(place_id);

    if (googleResponse.data.status !== "OK") {
      setServiceAreaError(null);
      setCalculationError("Sorry, we couldn't process your address");
      setCalculating(false);
      return;
    }

    const formatted_address = googleResponse.data.results[0].formatted_address;
    const { addr, city, state, zip } = extractAddressComponents(formatted_address);

    if (!serviceZipCodes.includes(zip)) {
      setServiceAreaError("Sorry, we don't currently service your area");
      setCalculationError(null);
      setCalculating(false);
      return;
    }

    const estimateResponse = await createOnlineEstimate(addr, city, state, zip);

    if (estimateResponse.status === 200) {
      setEstimate(estimateResponse.data);
      setCalculationError(null);
    } else {
      setServiceAreaError(null);
      setCalculationError("Sorry, we couldn't process your address");
    }

    setCalculating(false);
  }, [address]);

  return (
    <div className="App">
      <Container>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
          <a href="https://toddthomaspainting.com">
            <img src={Logo} alt="Todd Thomas Painting Logo" style={{ maxWidth: '200px', marginRight: '1rem' }} />
          </a>
          <h1>Exterior Painting Estimate Calculator</h1>
        </div>
        <br />
        <p>
          <strong>Welcome to our Exterior Painting Estimate Calculator!</strong>
        </p>
        <p> Simply enter your home address below, and we'll provide you with a general estimate for our 3 painting service packages.
        </p>
        <Form>
          <Form.Group controlId="address">
            <Form.Label
              style={{
                display: 'block',
                marginBottom: '0.5rem',
                fontWeight: 'bold',
                fontSize: '1.5rem',
              }}
            >Address</Form.Label>
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              selectProps={{
                value: address,
                onChange: handleAddressChange,
              }}
            />
          </Form.Group>
          <br />
          <Button
            onClick={handleCalculateEstimate}
            disabled={!address || calculating}
          >
            Calculate Estimate
          </Button>
        </Form>
        {calculating && (
          <div>
            <br />
            <Spinner animation="border" role="status" />
            <p>Calculating your estimate...</p>
          </div>
        )}

        {calculationError && (
          <>
            <br />
            <NoData />
            <Estimates />
          </>
        )}

        {serviceAreaError && <DontService />}

        {estimate && (
          <div>
            <br />
            <Estimates
              deluxePrice={estimate.pricing.deluxePrice}
              ultimatePrice={estimate.pricing.ultimatePrice}
              maximumPrice={estimate.pricing.maximumPrice}
            />
          </div>
        )}
      </Container>
      <br />
      <Container>
        <p>Phone Number: <a href="tel:+16234695513">(623) 469-5513</a></p>
        <p>Email: <a href="mailto:todd@toddthomaspainting.com">todd@toddthomaspainting.com</a></p>

        <h5>Disclaimer</h5>
        <p>
          Please note that all estimates provided here are intended to give
          homeowners a general idea of the expected costs and are subject
          to change based on the condition of the home and the specific scope
          of work required. For an official estimate, please schedule a free
          in-person estimate with our team. <strong>This online estimate is not a legally binding contract.</strong>
        </p>
      </Container>
    </div>
  );
}

export default App;
