import React from 'react';
import { Container, Card } from 'react-bootstrap';

interface EstimatesProps {
    deluxePrice?: number;
    ultimatePrice?: number;
    maximumPrice?: number;
}
export default function Estimates({ deluxePrice, ultimatePrice, maximumPrice }: EstimatesProps) {
    return (
        <>
            <h4>
                INCLUDED IN ALL PACKAGES
            </h4>
            <ul>
                <li>CLEAR AREA -  Base of home and areas to be painted is cleared of all debris & rock</li>
                <li>CLEAN - Home is pressure washed to remove dirt, grime, & loose paint.</li>
                <li>COVER - Mask & tape all areas not to be painted including windows and lights.</li>
                <li>REPAIR - Patch minor stucco cracks within scope of work.</li>
                <li>DOORS - Painted Doors including garage door included in estimate.</li>
                <li>COLOR CHOICE - Assistance in selecting the ideal approved colors for your home.</li>
                <li>HOA APPOVAL - Expedite all HOA Project approval if applicable.</li>
            </ul>
            <Container style={{ display: 'flex' }}>
                <Card
                    style={{
                        backgroundColor: "#FD9D1A",
                        marginRight: '10px' // Add some spacing between cards
                    }}
                >
                    <Card.Body>
                        <Card.Title>Deluxe</Card.Title>
                        <Card.Text>
                            <ul>
                                <li>Sherwin Williams Super Paint OR Dunn Edwards VersaFlat</li>
                                <li>Expected Life of Paint: 3-5 Years</li>
                                <li>2 Year Limited Warranty</li>
                            </ul>
                        </Card.Text>
                        {deluxePrice &&
                            <Card.Text>
                                <strong>Estimated Price: ${deluxePrice}</strong>
                            </Card.Text>}
                    </Card.Body>
                </Card>
                <Card
                    style={{
                        backgroundColor: "#989395",
                        marginRight: '10px' // Add some spacing between cards
                    }}
                >
                    <Card.Body>
                        <Card.Title>Ultimate</Card.Title>
                        <Card.Text>
                            <ul>
                                <li>Sherwin Williams Latitude OR Dunn Edwards SpartaShield</li>
                                <li>Expected Life of Paint: 5-7 Years</li>
                                <li>2 Year Limited Warranty</li>
                            </ul>
                        </Card.Text>
                        {ultimatePrice &&
                            <Card.Text>
                                <strong>Estimated Price: ${ultimatePrice}</strong>
                            </Card.Text>}
                    </Card.Body>
                </Card>
                <Card
                    style={{
                        backgroundColor: "#25599C"
                    }}
                >
                    <Card.Body>
                        <Card.Title>Maximum</Card.Title>
                        <Card.Text>
                            <ul>
                                <li>Sherwin Williams Duration OR Dunn Edwards EverShield</li>
                                <li>Expected Life of Paint: 7-10 Years</li>
                                <li>2 Year Limited Warranty</li>
                            </ul>
                        </Card.Text>
                        {maximumPrice &&
                            <Card.Text>
                                <strong>Estimated Price: ${maximumPrice}</strong>
                            </Card.Text>
                        }
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}
